.header {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 24.2rem;
  background-image: url('../assets/header-bg.png');
  background-size: cover;

  &-group {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  &-subheading {
    color: #979797;
    // color: #005192;
    font-family: $font-secondary;
    font-size: 4.8rem;
    line-height: 3.6rem;
    margin-top: 2rem;
  }
  &-logo {

  }
}


.horizontal-bar {
  width: 65.5rem;
  height: 0.1rem;
  background-color: rgba(151, 151, 151, 0.5);
}


.form {
  display: flex;
  justify-content: center;
  &-main {
    width: 65.5rem;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 5.3rem;
  }

  &-section-title {
    align-self: flex-start;

    color: #005192;
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 500;
    margin-top: 2.8rem;
  }

  &-field {
    width: 31.8rem;
    height: 4rem;
    border: 0.1rem solid #979797;
    background-color: #ffffff;
    padding: 5px 0 0 1.5rem;

    &-group {
      display: flex;
      align-items: center;
    }

    // Style for "Full name"
    &::placeholder{
      color: rgba(77, 77, 79, 0.49);
      font-family: $font-primary;
      font-size: 1.6rem;
      font-weight: 500;
    }

    &:not(:last-child) {
      margin-right: 2.6rem;
    }
  }

  &-button-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3rem 0;

    &-6 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 3rem 0;

    }

    &-row {
      width: 100%;
      justify-content: space-between;
      display: flex;

      &:not(:last-child) {
        margin-bottom: 3rem
      }
    }
  }
  &-field-group {
    display: flex;
    margin-bottom: 3.2rem;
  }

  &-radio {

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-label {
      display: flex;
      width: 12rem;
      position: relative;
      padding-right: 3.5rem;
      cursor: pointer;
      align-items: center;
      padding-top: 1rem;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      color: #4d4d4f;
      font-family: $font-primary;
      font-size: 1.6rem;
      font-weight: 500;
      user-select: none;

    }
  }
  &-appointment-label {
    color: #4d4d4f;
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 500;
    margin-right: 3rem;
  }
  &-booking {
    align-self: flex-start;
    width: 100%;
  }
}

fieldset {
  border: none;
}

input {
  font-size: 1.6rem;
}

/* Hide the browser's default radio button */
.form-radio-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  user-select: none;

}

/* Create a custom radio button */
.form-radio-checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 4rem;
  width: 4rem;
  border: 0.1rem solid #979797;
  background-color: #ffffff;
  // border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.form-radio-label:hover input ~ .form-radio-checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.form-radio-label input:checked ~ .form-radio-checkmark {
  background-color: #005192;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.form-radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.form-radio-label input:checked ~ .form-radio-checkmark:after {
  display: block;
  fill: currentColor;
  // width: 1em;
  // height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
}

/* Style the indicator (dot/circle) */
.form-radio-label .form-radio-checkmark:after {
  font-size: 2rem;
 	top: 7px;
	left: 7px;
	width: 2.1rem;
	height: 2.1rem;
	// border-radius: 50%;
	background: white;


}

.text-area {
  margin-top: 3.3rem;
  width: 66.2rem;
  height: 12rem;
  border: 0.1rem solid #979797;
  background-color: #ffffff;
  font-family: $font-primary;
  font-size: 1.6rem;
}

option {

}

.geosuggest {
  &__suggests {
    position: absolute;
    z-index: 100;
    background-color: white;

    &--hidden {
      display: none
    }
  }

  &__item {
    font-family: $font-primary;
    font-size: 1.6rem;
    margin-bottom: 1rem;

  }
}


ul {
  list-style: none;
  border: 1px solid #eeeccc;
  margin-top: 1rem;
  box-shadow: 0.8rem 0.5rem 2rem #aaa;
  border-top: none;
  padding: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.form-button {
  width: 67.1rem;
  height: 4rem;
  background-color: #005192;
  color: white;
  font-family: $font-primary;
  font-size: 1.6rem;
  margin-bottom: 3rem;
}
