
@import "./abstracts/mixins";
@import "./abstracts/variables";

@import "./components";


@font-face {
  font-family: 'Antro Vectra';
  src: url('../assets/fonts/Antro_Vectra.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../assets/fonts/FuturaLT.woff2') format('woff2'),
        url('../assets/fonts/FuturaLT.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}





*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// CSS Vars for Javascript:

:root {
  --animation-time: 2s;
}

html {
  max-width: 100vw;

  font-size: 62.5%;
  // font-size: 100%;

  @include respond(tabLand) { // width < 1200
    font-size: 56.25%; // 9px
  }

  @include respond(tabPort) { // width < 900
    font-size: 50%; // 8px
  }

  @include respond(bigDesktop) {
    font-size: 75%; // 12px
  }


}

body {
  box-sizing: border-box;

  @include respond(tabPort) { // width < 900
    padding: 0;
  }
}
